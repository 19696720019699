// --------------------------------create------------------------------------------------------
export const GET_SUBCODE_REQUEST = 'GET_SUBCODE_REQUEST';
export const GET_SUBCODE_SUCCESS_REQUEST = 'GET_SUBCODE_SUCCESS_REQUEST';
export const GET_SUBCODE_FAILURE_REQUEST = 'GET_SUBCODE_FAILURE_REQUEST';

// --------------------------------list------------------------------------------------------
export const GET_PRODUCT_SEARCH_REQUEST = 'GET_PRODUCT_SEARCH_REQUEST';
export const GET_PRODUCT_SEARCH_SUCCESS_REQUEST = 'GET_PRODUCT_SEARCH_SUCCESS_REQUEST';
export const GET_PRODUCT_SEARCH_FAILURE_REQUEST = 'GET_PRODUCT_SEARCH_FAILURE_REQUEST';

export const GET_ADVANCE_FILTER_DROPDOWN_REQUEST = 'GET_ADVANCE_FILTER_DROPDOWN_REQUEST';
export const GET_ADVANCE_FILTER_DROPDOWN_SUCCESS_REQUEST = 'GET_ADVANCE_FILTER_DROPDOWN_SUCCESS_REQUEST';
export const GET_ADVANCE_FILTER_DROPDOWN_FAILURE_REQUEST = 'GET_ADVANCE_FILTER_DROPDOWN_FAILURE_REQUEST';

export const GET_BASIC_TEMPLATE_DATA_REQUEST = 'GET_BASIC_TEMPLATE_DATA_REQUEST';
export const GET_BASIC_TEMPLATE_DATA_SUCCESS = 'GET_BASIC_TEMPLATE_DATA_SUCCESS';
export const GET_BASIC_TEMPLATE_DATA_FAILURE = 'GET_BASIC_TEMPLATE_DATA_FAILURE';

export const GET_CIN_LOG_MSG_REQUEST = 'GET_CIN_LOG_MSG_REQUEST';
export const GET_CIN_LOG_MSG_SUCCESS_REQUEST = 'GET_CIN_LOG_MSG_SUCCESS_REQUEST';
export const GET_CIN_LOG_MSG_FAILURE_REQUEST = 'GET_CIN_LOG_MSG_FAILURE_REQUEST';

export const GET_CIC_LOG_MSG_REQUEST = 'GET_CIC_LOG_MSG_REQUEST';
export const GET_CIC_LOG_MSG_SUCCESS_REQUEST = 'GET_CIC_LOG_MSG_SUCCESS_REQUEST';
export const GET_CIC_LOG_MSG_FAILURE_REQUEST = 'GET_CIC_LOG_MSG_FAILURE_REQUEST';

export const GET_PUBLISH_STATUS_LOG_REQUEST = 'GET_PUBLISH_STATUS_LOG_REQUEST';
export const GET_PUBLISH_STATUS_LOG_SUCCESS_REQUEST = 'GET_PUBLISH_STATUS_LOG_SUCCESS_REQUEST';
export const GET_PUBLISH_STATUS_LOG_FAILURE_REQUEST = 'GET_PUBLISH_STATUS_LOG_FAILURE_REQUEST';

export const GET_NBB_TARGETMARKET_LIST_REQUEST = 'GET_NBB_TARGETMARKET_LIST_REQUEST';
export const GET_NBB_TARGETMARKET_LIST_SUCCESS = 'GET_NBB_TARGETMARKET_LIST_SUCCESS';
export const GET_NBB_TARGETMARKET_LIST_FAILURE = 'GET_NBB_TARGETMARKET_LIST_FAILURE';

// ---------------------------------NBB-DASHBOARD-----------------------------------------------
export const GET_HYBRID_COMPLETENESS_SCORE_REQUEST = 'GET_HYBRID_COMPLETENESS_SCORE_REQUEST';
export const GET_HYBRID_COMPLETENESS_SCORE_SUCCESS_REQUEST = 'GET_HYBRID_COMPLETENESS_SCORE_SUCCESS_REQUEST';
export const GET_HYBRID_COMPLETENESS_SCORE_FAILURE_REQUEST = 'GET_HYBRID_COMPLETENESS_SCORE_FAILURE_REQUEST';

export const GET_HYBRID_PRODUCT_COUNT_BY_STATUS_REQUEST = 'GET_HYBRID_PRODUCT_COUNT_BY_STATUS_REQUEST';
export const GET_HYBRID_PRODUCT_COUNT_BY_SUCCESS_REQUEST_STATUS = 'GET_HYBRID_PRODUCT_COUNT_BY_SUCCESS_REQUEST_STATUS';
export const GET_HYBRID_PRODUCT_COUNT_BY_FAILURE_REQUEST_STATUS = 'GET_HYBRID_PRODUCT_COUNT_BY_FAILURE_REQUEST_STATUS';

export const GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_REQUEST = 'GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_REQUEST';
export const GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_SUCCESS_REQUEST = 'GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_SUCCESS_REQUEST';
export const GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_FAILURE_REQUEST = 'GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_FAILURE_REQUEST';

export const GET_NBB_RECENT_EDITED_PRODUCTS_REQUEST = 'GET_NBB_RECENT_EDITED_PRODUCTS_REQUEST';
export const GET_NBB_RECENT_EDITED_PRODUCTS_SUCCESS_REQUEST = 'GET_NBB_RECENT_EDITED_PRODUCTS_SUCCESS_REQUEST';
export const GET_NBB_RECENT_EDITED_PRODUCTS_FAILURE_REQUEST = 'GET_NBB_RECENT_EDITED_PRODUCTS_FAILURE_REQUEST';

export const GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_REQUEST = 'GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_REQUEST';
export const GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_SUCCESS_REQUEST = 'GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_SUCCESS_REQUEST';
export const GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_FAILURE_REQUEST = 'GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_FAILURE_REQUEST';

export const GET_HYBRID_PRODUCT_VALIDATION_COUNT_REQUEST = 'GET_HYBRID_PRODUCT_VALIDATION_COUNT_REQUEST';
export const GET_HYBRID_PRODUCT_VALIDATION_COUNT_SUCCESS_REQUEST = 'GET_HYBRID_PRODUCT_VALIDATION_COUNT_SUCCESS_REQUEST';
export const GET_HYBRID_PRODUCT_VALIDATION_COUNT_FAILURE_REQUEST = 'GET_HYBRID_PRODUCT_VALIDATION_COUNT_FAILURE_REQUEST';

export const GET_DASHBOARD_RSC_PRODUCT_COUNT_REQUEST = 'GET_DASHBOARD_RSC_PRODUCT_COUNT_REQUEST';
export const GET_DASHBOARD_RSC_PRODUCT_COUNT_SUCCESS_REQUEST = 'GET_DASHBOARD_RSC_PRODUCT_COUNT_SUCCESS_REQUEST';
export const GET_DASHBOARD_RSC_PRODUCT_COUNT_FAILURE_REQUEST = 'GET_DASHBOARD_RSC_PRODUCT_COUNT_FAILURE_REQUEST';

export const GET_DASHBOARD_COUNT_BY_CERTIFICATION_REQUEST = 'GET_DASHBOARD_COUNT_BY_CERTIFICATION_REQUEST';
export const GET_DASHBOARD_COUNT_BY_CERTIFICATION_SUCCESS_REQUEST = 'GET_DASHBOARD_COUNT_BY_CERTIFICATION_SUCCESS_REQUEST';
export const GET_DASHBOARD_COUNT_BY_CERTIFICATION_FAILURE_REQUEST = 'GET_DASHBOARD_COUNT_BY_CERTIFICATION_FAILURE_REQUEST';

export const GET_NBB_PRODUCT_IMAGE_TYPE_REQUEST = 'GET_NBB_PRODUCT_IMAGE_TYPE_REQUEST';
export const GET_NBB_PRODUCT_IMAGE_TYPE_SUCCESS = 'GET_NBB_PRODUCT_IMAGE_TYPE_SUCCESS';
export const GET_NBB_PRODUCT_IMAGE_TYPE_FAILURE = 'GET_NBB_PRODUCT_IMAGE_TYPE_FAILURE';
// -------------to-store-current-page-number-------------------------------------
export const HANDLE_NBB_CURRENT_PAGE = 'HANDLE_NBB_CURRENT_PAGE';

// -------------------------------------SSA-ACTION------------------------------------------

export const GET_SUPPLIER_SPECIFIC_ATTRIBUTE_REQUEST = 'GET_SUPPLIER_SPECIFIC_ATTRIBUTE_REQUEST';
export const GET_SUPPLIER_SPECIFIC_ATTRIBUTE_SUCCESS = 'GET_SUPPLIER_SPECIFIC_ATTRIBUTE_SUCCESS';
export const GET_SUPPLIER_SPECIFIC_ATTRIBUTE_FAILURE = 'GET_SUPPLIER_SPECIFIC_ATTRIBUTE_FAILURE';

// -------------------------STATIC-FILES-ACTIONS-----------------------------------------
export const GET_CODELIST_REQUEST = 'GET_CODELIST_REQUEST';
export const GET_CODELIST_SUCCESS_REQUEST = 'GET_CODELIST_SUCCESS_REQUEST';
export const GET_CODELIST_FAILURE_REQUEST = 'GET_CODELIST_FAILURE_REQUEST';

export const GET_VALIDATION_DATA_REQUEST = 'GET_VALIDATION_DATA_REQUEST';
export const GET_VALIDATION_DATA_SUCCESS_REQUEST = 'GET_VALIDATION_DATA_SUCCESS_REQUEST';
export const GET_VALIDATION_DATA_FAILURE_REQUEST = 'GET_VALIDATION_DATA_FAILURE_REQUEST';

export const GET_VALIDATION_LABEL_REQUEST = 'GET_VALIDATION_LABEL_REQUEST';
export const GET_VALIDATION_LABEL_SUCCESS_REQUEST = 'GET_VALIDATION_LABEL_SUCCESS_REQUEST';
export const GET_VALIDATION_LABEL_FAILURE_REQUEST = 'GET_VALIDATION_LABEL_FAILURE_REQUEST';

export const GET_GPC_STATIC_FILE_REQUEST = 'GET_GPC_STATIC_FILE_REQUEST';
export const GET_GPC_STATIC_FILE__SUCCESS_REQUEST = 'GET_GPC_STATIC_FILE__SUCCESS_REQUEST';
export const GET_GPC_STATIC_FILE_FAILURE_REQUEST = 'GET_GPC_STATIC_FILE_FAILURE_REQUEST';

export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS_REQUEST = 'GET_GROUPS_SUCCESS_REQUEST';
export const GET_GROUPS_FAILURE_REQUEST = 'GET_GROUPS_FAILURE_REQUEST';


// -----------------connect max actions---------------------------------

export const GET_CONNECT_MAX_SAVED_LAYOUTS="GET_CONNECT_MAX_SAVED_LAYOUTS";
export const GET_CONNECT_MAX_SAVED_LAYOUTS_SUCCESS="GET_CONNECT_MAX_SAVED_LAYOUTS_SUCCESS";
export const GET_CONNECT_MAX_SAVED_LAYOUTS_FAILURE="GET_CONNECT_MAX_SAVED_LAYOUTS_FAILURE";

export const HYBRID_GET_USER_UPLOADED_ASSET_REQUEST="HYBRID_GET_USER_UPLOADED_ASSET_REQUEST";
export const HYBRID_GET_USER_UPLOADED_ASSET_SUCCESS="HYBRID_GET_USER_UPLOADED_ASSET_SUCCESS";
export const HYBRID_GET_USER_UPLOADED_ASSET_FAILURE="HYBRID_GET_USER_UPLOADED_ASSET_FAILURE";

export const GET_HYBRID_PRODUCT_IMAGES="GET_HYBRID_PRODUCT_IMAGES";
export const GET_HYBRID_PRODUCT_IMAGES_SUCCESS="GET_HYBRID_PRODUCT_IMAGES_SUCCESS";
export const GET_HYBRID_PRODUCT_IMAGES_FAILURE="GET_HYBRID_PRODUCT_IMAGES_FAILURE";