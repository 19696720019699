import * as Constants from '../constant/constant';

export const isAllowed = (action, permissionArr, serviceName) => {
  if (permissionArr && permissionArr[action] && permissionArr[action][serviceName]) {
    return (permissionArr[action][serviceName].length > 0);
  }
};

export const isAllowedForSubCode = (action, SubCode, permissionArr, serviceName) => {
  let allowed = false;
  if (permissionArr && permissionArr[action] && permissionArr[action][serviceName]) {
    const exists = permissionArr[action][serviceName].filter((p) => (p == SubCode || p == 'all'));
    allowed = (exists.length > 0);
  }
  return allowed;
};

export const handlePermissionForSubCode = (action, status, SubCode, permissionArr, serviceName) => {
  if ((serviceName === Constants.SERVICES_NAMES.CERTIFIED) || (serviceName === Constants.SERVICES_NAMES.NBBCORE)) {
    switch (action) {
      case 'edit':
        return (
          isAllowedForSubCode(action, SubCode, permissionArr, 'topup') || 
          isAllowedForSubCode(action, SubCode, permissionArr, Constants.SERVICES_NAMES.RSC)
        );
      case 'approve':
        switch (status) {
          case Constants.PRODUCT_STATUS.AWAITING_CORE_DATA_APPROVAL:
            return isAllowedForSubCode(action, SubCode, permissionArr, 'core');
          case Constants.PRODUCT_STATUS.AWAITING_APPROVAL:
            return isAllowedForSubCode(action, SubCode, permissionArr, 'topup');
          case Constants.PRODUCT_STATUS.AWAITING_PRE_APPROVAL:
            return isAllowedForSubCode(action, SubCode, permissionArr, 'topup');
        }
        break;
      case 'reject':
        switch (status) {
          case Constants.PRODUCT_STATUS.AWAITING_CORE_DATA_APPROVAL:
            return isAllowedForSubCode(action, SubCode, permissionArr, 'core');
          case Constants.PRODUCT_STATUS.AWAITING_APPROVAL:
            return isAllowedForSubCode(action, SubCode, permissionArr, 'topup');
        }
    }
  } else {
    return isAllowedForSubCode(action, SubCode, permissionArr, serviceName);
  }
};

export const handlePermission = (action, permissionArr, serviceName) => {
  if ((serviceName === Constants.SERVICES_NAMES.CERTIFIED) || (serviceName === Constants.SERVICES_NAMES.AWAITING_BRANDBANK_CAPTURED_DATA_REVIEW)) {
    return (isAllowed(action, permissionArr, 'topup') || isAllowed(action, permissionArr, 'core'));
  }
  return isAllowed(action, permissionArr, serviceName);
};

export const checkProductLicense = (licenseArr, serviceName) => licenseArr && licenseArr.includes(serviceName);

export const migratedProductViewAllowed = (action, permissionArr, serviceName) => {
  let allowed = false;
  if (permissionArr && permissionArr[action] && permissionArr[action][serviceName]) {
    allowed = permissionArr[action][serviceName].includes('all');
  }
  return allowed;
};

export const isSubcodeAllowedForViewCompleteness = (subCode, viewOnlySubcodeArr) => {
  let allowed = false;
  if (viewOnlySubcodeArr && viewOnlySubcodeArr.length) {
    const exists = viewOnlySubcodeArr.filter((code) => (code == subCode));
    allowed = (exists.length > 0);
  }
  return allowed;
};

export const checkRestrictedSubcode = (hideContentHealthScore, viewSubcodeCertified) => {
  const result = viewSubcodeCertified?.every((val) => hideContentHealthScore?.includes(val));
  return result;
};

/* CH+ Methods */
export const checkChPlusEnabledTM = (userDetails) => (!!((userDetails && userDetails.isCHPFeatureEnable && userDetails.chPlusTargetMarkets?.length)));

export const isCHPlusFeatureExists = (userDetails) => (!!((userDetails
    && userDetails.isCHPFeatureEnable
    && userDetails.chPlusTargetMarkets?.length
    && userDetails.chpEnableSubcodes?.length)));

export const checkNonCHPSubcodes = (userDetails) => {
  const certifiedSubcodes = (userDetails && userDetails.permission && userDetails.permission.view.certified) ? userDetails.permission.view.certified : [];
  if (certifiedSubcodes.length && (userDetails && userDetails.isCHPFeatureEnable)) {
    const chpSubcodes = userDetails.chpEnableSubcodes;
    const isNonChpSubcodeExists = (chpSubcodes && chpSubcodes.length) ? certifiedSubcodes.filter((subcode) => !chpSubcodes.includes(subcode)).length : true;
    return !!(isNonChpSubcodeExists);
  }
  return true;
};

export const checkProductHaveCHPLicense = (productSubcode, userDetails) => {
  const chpSubcodeList = userDetails?.chpEnableSubcodes;
  return (chpSubcodeList && userDetails.isCHPFeatureEnable) ? chpSubcodeList.includes(parseInt(productSubcode)) : false;
};

/* End CH+ Methods */
