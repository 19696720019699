import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
    GET_CONNECT_MAX_SAVED_LAYOUTS_SUCCESS,
    GET_CONNECT_MAX_SAVED_LAYOUTS_FAILURE,
    HYBRID_GET_USER_UPLOADED_ASSET_SUCCESS,
    HYBRID_GET_USER_UPLOADED_ASSET_FAILURE,
    GET_HYBRID_PRODUCT_IMAGES_SUCCESS,
    GET_HYBRID_PRODUCT_IMAGES_FAILURE
} from '../../../actions/nbb';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';
import { HYBRID_GET_SAVED_LAYOUTS,HYBRID_GET_USER_UPLOADED_ASSETS,HYBRID_GET_BRANDBANK_ASSETS } from '../../../../constant/Apis';

// ---------------------------------get-saved-layout-----------------------------------------------
export function getConnectMaxSavedLayout(url, token, productId) {
    return axiosApiCall.get(url, {
        params: {
            _dc: getDcTime(),
            productId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

export function* getConnectMaxSavedLayoutSaga(action) {
    try {
        const token = yield call(getIdToken, action.data.auth0);
        const response = yield call(getConnectMaxSavedLayout, HYBRID_GET_SAVED_LAYOUTS, token, action.data.productId);
        let { data } = response.data;
        yield put({ type: GET_CONNECT_MAX_SAVED_LAYOUTS_SUCCESS, data });
    } catch (error) {
        yield put({ type: GET_CONNECT_MAX_SAVED_LAYOUTS_FAILURE, error });
    }
}

// ---------------------------------get-user-uploaded-assets-----------------------------------------------
export function getUserUploadedAssets(url, token, productId) {
    return axiosApiCall.get(url, {
        params: {
            _dc: getDcTime(),
            productId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

export function* getUserUploadedAssetsSaga(action) {
    try {
        const token = yield call(getIdToken, action.data.auth0);
        const response = yield call(getUserUploadedAssets, HYBRID_GET_USER_UPLOADED_ASSETS, token, action.data.productId);
        let { data } = response.data;
        yield put({ type: HYBRID_GET_USER_UPLOADED_ASSET_SUCCESS, data });
    } catch (error) {
        yield put({ type: HYBRID_GET_USER_UPLOADED_ASSET_FAILURE, error });
    }
}

// ----------------------------get-hybrid-product-images-------------------------------------------
export function getHybridProductImages(url, token) {
    return axiosApiCall.get(url, {
        params: {
            _dc: getDcTime()
        },
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

export function* getHybridProductImagesSaga(action) {
    try {
        const token = yield call(getIdToken, action.data.auth0);
        const response = yield call(getHybridProductImages, HYBRID_GET_BRANDBANK_ASSETS(action.data.productId), token );
        let { data } = response.data;
        yield put({ type: GET_HYBRID_PRODUCT_IMAGES_SUCCESS, data });
    } catch (error) {
        yield put({ type: GET_HYBRID_PRODUCT_IMAGES_FAILURE, error });
    }
} 


